ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
ul, li {
    list-style-type: none;
}
li {
    display: list-item;
    text-align: -webkit-match-parent;
}
.portfolioboxes .portwrappp {
    text-align: center;
  }
  .portfolioboxes .tab-custom .tabs {
    display: none;
  }
  .portfolioboxes .portwrappp .mportt {
    display: inline-block;
    width: 100%;
    font-size: 0;
  }
  @media screen and (max-width: 736px){
  .portfolioboxes .portwrappp .mportt li {
      width: 100%;
  }}
  .portfolioboxes .portwrappp .mportt li {
      width: 33.33%;
      display: inline-block;
      padding: 10px;
  }
  .portfolioboxes .portwrappp .mportt li a {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  a {
    text-decoration: none;
  }
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }
  figure {
    margin: 0 0 1rem;
  }
  .portfolioboxes .portwrappp .mportt li a figure img {
    width: 100%;
  }
  img {
    overflow-clip-margin: content-box;
    overflow: clip;
}
img {
    border: 0px;
    outline: none;
}
img {
    width: 100%;
}
img {
    vertical-align: middle;
    border-style: none;
}
.container-custom {
    max-width: 1285px;
    margin: auto;
    padding: 0 15px;
}
.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.col-lg-12{
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;

}